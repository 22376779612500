import React from "react";
import Link from "next/link";

interface MenuItemProps {
  href: string;
  icon: React.ReactNode;
  text: React.ReactNode | string;
  active?: boolean;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  href,
  icon,
  text,
  active = false,
}) => {
  return (
    <Link
      href={href}
      className={`
        block mb-2 p-2 rounded-lg transition-all duration-300 ease-in-out
        ${
          active
            ? "bg-gradient-to-r from-indigo-500 to-purple-500 text-white font-bold shadow-lg"
            : "text-gray-700 hover:bg-gradient-to-r hover:from-indigo-100 hover:to-purple-100"
        }
        transform hover:scale-105 hover:shadow-md
      `}
    >
      <div className="flex items-center">
        <span
          className={`transition-transform duration-300 ${
            active ? "scale-110" : "group-hover:scale-110"
          }`}
        >
          {icon}
        </span>
        <span
          className={`ml-2 transition-all duration-300 ${
            active ? "translate-x-1" : "group-hover:translate-x-1"
          }`}
        >
          {text}
        </span>
      </div>
    </Link>
  );
};
