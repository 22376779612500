import { useAuth, useOrganizationList } from "@clerk/nextjs";

export const useOwner = () => {
  const { isLoaded, userId } = useAuth();
  const { userMemberships } = useOrganizationList({
    userMemberships: { pageSize: 100 },
  });

  const belongsToOrganization =
    userMemberships?.data?.some((org) => {
      return (
        (org.organization.slug === "revues" ||
          org.organization.slug === "admins") &&
        org.role == "admin"
      );
    }) || false;

  return belongsToOrganization;
};
