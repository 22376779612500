import React from "react";
import Image from "next/image";

export const Logo: React.FC = () => {
  return (
    <div className="flex items-center md:mb-8">
      <Image
        src="/logo.png"
        width={56}
        height={56}
        alt="Logo"
        className="relative top-0 left-0 w-8 h-8 object-contain mr-2"
      />
      <Image
        src="/text.png"
        width={524}
        height={57}
        alt="Logo Text"
        className="relative top-0 left-0 w-24 h-16 object-contain mr-2"
      />
    </div>
  );
};
