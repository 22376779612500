"use client";
import React, { useState } from "react";
import { usePathname } from "next/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import { Logo } from "../components/Logo";
import { MenuItem } from "../components/MenuItem";
import { Menu } from "../components/Menu";
import { Badge } from "../components/ui/badge"; // Added this import
import {
  OrganizationSwitcher,
  SignedIn,
  UserButton,
  useAuth,
} from "@clerk/nextjs";
import { Toaster } from "react-hot-toast";
import Link from "next/link";
import { useOwner } from "../hooks/useAdmin";
import {
  Building2,
  LockIcon,
  MessageCircleIcon,
  WalletIcon,
  CommandIcon,
  User2,
  Star,
  Settings,
} from "lucide-react";

function MenuLayout() {
  const { userId } = useAuth();
  const belongsToOrganization = useOwner();

  const user = userId;

  const pathname = usePathname();
  const [menuVisible, setMenuVisible] = useState(false);
  const menuItems = [
    {
      href: "/dashboard",
      icon: <CommandIcon className="" />,
      text: (
        <span className="flex items-center gap-1.5">
          <span className="font-medium">Dashboard</span>
          <Badge
            variant="secondary"
            className="bg-purple-300 text-purple-800 text-xs px-1.5 py-0.5"
          >
            Beta
          </Badge>
        </span>
      ),
      active: pathname?.includes("dashboard"),
    },
    {
      href: "/accounts",
      icon: <User2></User2>,
      text: "My Accounts",
      active: pathname?.includes("accounts"),
    },
    {
      href: "/businesses",
      icon: <Building2></Building2>,
      text: "My Businesses",
      active: pathname?.includes("businesses"),
    },
    {
      href: "/complaints",
      icon: <MessageCircleIcon />,
      text: "Complaints",
      active: pathname?.includes("complaints"),
    },
    {
      href: "/reviews",
      icon: <Star />,
      text: "Reviews",
      active: pathname?.includes("reviews"),
    },
    {
      href: "/plans",
      icon: <WalletIcon />,
      text: "Subscriptions",
      active: pathname?.includes("/plans"),
    },
    {
      href: "/settings",
      icon: <Settings />,
      text: "White Label Settings",
      active: pathname?.includes("settings"),
    },
  ];
  return (
    <div className={`md:min-h-screen md:flex md:flex-row flex flex-col`}>
      <Toaster />
      <div className="absolute top-0 right-0 z-50 flex invisible mt-4 mr-4 space-x-4 md:visible">
        <UserButton afterSignOutUrl="/" />
      </div>
      <div
        className={`md:w-64 md:p-4 p-2 sm:w-full bg-slate-200 bg-opacity-80 shadow-lg relative`}
      >
        <div className="flex items-center justify-between">
          <Link href="/">
            <Logo />
          </Link>{" "}
          <div className="flex items-center">
            <div className="z-auto flex ml-2 space-x-4 md:hidden">
              <UserButton afterSignOutUrl="/" />
            </div>
            <button
              className="z-10 p-2 text-white md:hidden"
              onClick={() => setMenuVisible(!menuVisible)}
            >
              <FontAwesomeIcon
                icon={faBars}
                style={{ color: "#000000" }}
                size="xl"
                width={16}
              />{" "}
            </button>
          </div>
        </div>
        <div className={`${menuVisible ? "block" : "hidden"} md:block`}>
          {!user && (
            <>
              <MenuItem
                href="/sign-in"
                icon={<FontAwesomeIcon icon={faUser} width={16} />}
                text="Login"
                active={pathname?.includes("/sign-in")}
              />
            </>
          )}
          <SignedIn>
            {user && (
              <>
                <div className="w-full ">
                  <OrganizationSwitcher />
                </div>
                <Menu items={menuItems} />
              </>
            )}
          </SignedIn>
          <MenuItem
            href="/privacy-policy"
            icon={<LockIcon />}
            text="Privacy Policy"
            active={pathname === "/privacy-policy"}
          ></MenuItem>{" "}
        </div>
      </div>
    </div>
  );
}

export default MenuLayout;
