import React from "react";
import { MenuItem } from "./MenuItem";

interface MenuProps {
  items: {
    href: string;
    icon: React.ReactNode;
    text: React.ReactNode | string;
    active?: boolean;
  }[];
}

export const Menu: React.FC<MenuProps> = ({ items }) => {
  return (
    <div className="block">
      {items.map((item, index) => (
        <MenuItem
          key={index}
          href={item.href}
          icon={item.icon}
          text={item.text}
          active={item.active}
        />
      ))}
    </div>
  );
};
